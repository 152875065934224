import { withErrorHandling } from "./custom-event";

export const isValidDocument = (currentDocument: Document): boolean => {
  return !!(
    currentDocument &&
    typeof currentDocument === "object" &&
    typeof currentDocument.querySelector === "function"
  );
};

export const extractText = withErrorHandling("extractText")((
  selector: string | null | undefined,
  currentDocument: Document = document,
) => {
  if (!selector) {
    return "";
  }

  const documentToUse = isValidDocument(currentDocument) ? currentDocument : document;
  const element = documentToUse.querySelector(selector);
  return extractNestedTextContent(element).trim();
});

type CustomNode = Node & {
  tagName?: string;
};

// It is expected that we cannot trim the trailing beginning and ending whitespaces as this would require more
// complex logic to handle whitespaces between text nodes and html nodes. These trailing whitespaces are instead trimmed as part of `extractText`
function extractNestedTextContent(node: Node | null): string {
  if (!node) {
    return "";
  }

  let text: string = "";
  const customNode = node as CustomNode;
  if (node.nodeType === Node.TEXT_NODE) {
    // Trim duplicate whitespaces
    // Replace new lines
    text += (node.textContent || "").replace(/\s+/g, " ").replace(/\n/g, " ");
  } else if (customNode.tagName !== "A") { // Not great, but we avoid capturing accidental info links that are included with the text content.
    const childNodes = node.childNodes;
    for (let i = 0; i < childNodes.length; i++) {
      text += extractNestedTextContent(childNodes[i]);
    }
  }
  return text;
}

export const extractTextContent = withErrorHandling("extractTextContent")((
  selector: string | null | undefined,
  currentDocument: Document = document,
) => {
  if (!selector) {
    return "";
  }

  const documentToUse = isValidDocument(currentDocument) ? currentDocument : document;
  const element = documentToUse.querySelector(selector);
  if (!element) {
    return "";
  }

  return element.textContent;
});

export const extractNumberOfElements = withErrorHandling("extractNumberOfElements")((
  selector: string,
  currentDocument: Document = document,
) => {
  if (!selector) {
    return 0;
  }

  const documentToUse = isValidDocument(currentDocument) ? currentDocument : document;
  const elements = documentToUse.querySelectorAll(selector);
  return elements?.length;
});

export const extractSubStepNumberFromSelector = withErrorHandling("extractSubStepNumberFromSelector")((
  subStepSelector: string,
  subStepAllSelector: string,
  currentDocument: Document = document,
) => {
  if (!subStepSelector || !subStepAllSelector) {
    return 0;
  }

  const documentToUse = isValidDocument(currentDocument) ? currentDocument : document;
  const allSubSteps = documentToUse.querySelectorAll(subStepAllSelector);
  const currentSubStep = documentToUse.querySelector(subStepSelector);

  let subStepNumber = 0;
  for (const [index, subStep] of allSubSteps.entries()) {
    if (subStep === currentSubStep) {
      subStepNumber = index + 1;
      break;
    }
  }
  return subStepNumber;
});
